.home_block_1.spacer {
	@media(max-width: 991px) {
		padding: 35px 25px;
	}

	&:before {
		display: none !important;
	}
}

.home_block_2 {
	.container-two-columns {
		@media(max-width: 576px) {
			padding-left: 0;
			padding-right: 0;
		}

		.container-holder {
			margin: 0;

			.column {
				padding: 0;
			}
		}
	}

	.column.two {
		display: flex;
		flex-flow: column wrap;
		justify-content: center;

		h2 {
			max-width: 530px;
			margin-left: auto;
			margin-right: auto;
		}

		p {
			max-width: 405px;
			margin-left: auto;
			margin-right: auto;
		}

		.btn {
			margin-left: auto;
			margin-right: auto;
			display: block;
		}

		.link {
			@extend .btn;
			@extend .btn-transparent;
		}
	}
}

.home_block_icons.page_block.grid {
	max-width: 840px;
	margin-left: auto;
	margin-right: auto;

	.item {
		padding-left: 0;
		padding-right: 0;
	}
}

.partners-section {


	.container-holder {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 0;

		.column {
			margin-bottom: 0;

			&.one {
				text-align: center;
				padding-top: 60px;
				@media(max-width: 991px) {
					margin-bottom: 35px;
				}

				.resource_image {
					margin-bottom: 15px;
				}
			}

			&.two {
				text-align: center;
				@include media-breakpoint-up(lg) {
					text-align: right;
					padding-right: 0;
				}
				@media(max-width: 991px) {
					padding-left: 0 !important;
					padding-right: 0 !important;
					order: -1;
				}
			}
		}
	}
}


.floating-slider-logos {
	@include media-breakpoint-up(lg) {
		position: absolute;
		right: 15px;
		top: 135px;
		width: 29%;
		max-width: 540px;
		z-index: 2;
	}

	.container-holder {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;
		align-items: center;

		.resource_image {
			flex: 0 0 calc(33.333% - 35px);
			max-width: calc(33.333% - 35px);
		}
	}
}
