.usp-section {
	padding: 55px 0;

	.container-holder {
		@media(max-width: 991px) {
			display: flex;
			justify-content: center;
		}
	}

	ul {
		list-style: none;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		margin-bottom: 0;
		@media(max-width: 991px) {
			display: inline-block;
		}

		li {
			color: #fff;
			font-size: 1.125rem;
			text-transform: uppercase;
			font-weight: 600;
			@media(max-width: 991px) {
				display: block;
				width: 100%;
			}

			&:before {
				content: '\f14a';
				font-family: 'Font Awesome 5 Pro';
				margin-right: 15px;
				font-weight: 500;
			}

			&:not(:last-of-type) {
				margin-right: 65px;
			}
		}
	}
}
