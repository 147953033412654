.owl-carousel {
	background: $primary;
}

header {
	&.header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;

		// notice-bar
		.notice-bar {
			&.show {
				display: block;
			}

			display: none;
			text-align: center;
			overflow: hidden;
			position: fixed;
			top: 75%;
			right: 35px;
			transform: translate(0, -50%);
			max-width: 550px;
			padding: 25px 25px 35px;
			background: #ffffff;
			box-shadow: 0 0 20px 20px #030c1d50;

			.notice-bar-content-description {
				padding-right: 0;

				p:last-of-type {
					margin-bottom: 0;
				}

				* {
					color: #030C1D;
					text-align: left;
				}

				h2 {
					margin-bottom: 0;

					+ p {
						margin-bottom: 15px !important;
					}
				}

				ul {
					list-style: none;
					padding-left: 0;

					li {
						&:not(:last-of-type) {
							margin-bottom: 15px;
						}
					}
				}

				a.btn {
					color: #030C1D;
					background: #fff;
					text-transform: uppercase;
					font-size: 1.125rem;
					border-radius: 5px;
					font-weight: 500;
					padding-left: 0.8rem;
					padding-right: 0.8rem;
					border: 1px solid #fff;
					padding: 4px 15px;
				}

				a {
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}
			}

			.notice-bar-close {
				position: absolute;
				top: 5px;
				right: 15px;

				&-icon {
					font-size: 20px;
				}

				&-title {
					display: none;
				}
			}
		}

		// header-socket
		.header-socket {
			background-color: transparent;

			.container-holder.open-menu {
				background-color: $primary;
			}

			.container-navbar {
				.column.navbar {
					padding: 22px 30px;
					@media(max-width: 991px) {
						display: flex;
						flex-flow: row wrap;
						justify-content: flex-start !important;
					}

					.logo {
						flex: 0 0 10%;
						max-width: 50%;
						@media(max-width: 991px) {
							flex: 0 0 50%;
						}

						img {
							@media(max-width: 991px) {
								max-width: 50%;
							}
						}
					}

					.menu {
						justify-content: flex-end;
						@media(max-width: 991px) {
							justify-content: center;
							padding-bottom: 40px;
						}

						ul.navbar-nav {
							> li {
								&:not(:first-of-type) {
									margin-left: 15px;
								}

								> a {
									font-size: 1rem;
									font-weight: 600;
									color: #fff;

									.menu-icon {
										margin-right: 5px;
									}
								}

								&.active {
									> a {
										opacity: .7;
									}
								}

								&.last {
									> a.nav-link {
										color: $primary;
										background: #fff;
										text-transform: uppercase;
										font-size: 1.125rem;
										border-radius: 5px;
										font-weight: 500;
										padding-left: 0.8rem;
										padding-right: 0.8rem;
										border: 1px solid #fff;
										@media(max-width: 991px) {
											max-width: calc(100% - 30px);
										}

										&:hover {
											color: #fff;
											background: $primary;
										}
									}
								}
							}
						}
					}
				}

				@media(max-width: 991px) {
					.navbar-collapse {
						position: fixed;
						top: 84px;
						display: none;
						left: 0;
						background: $primary;
						width: 100vw;

						&#default_menu {
							&:not(.show) {
								display: none !important;
							}

							.navbar-nav {
								display: flex;
								flex-flow: row wrap;

								li {
									display: block;
									flex: 0 0 100%;
									max-width: 100%;
								}
							}
						}
					}
					.navbar-toggler {
						display: inline-block !important;
						order: 1;
						max-width: 40px;
						border: none;

						.navbar-toggler-icon {
							background-image: none !important;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
					.site_switcher {
						flex: 0 0 calc(50% - 40px);
						max-width: calc(50% - 40px);

						.language {
							justify-content: flex-end;
						}
					}
				}
			}
		}

		.dropdown-menu {
			> li {
				> a {
					color: $primary !important;
				}
			}
		}
	}

	&.sticky {
		background: $primary;
	}
}

.banner_logos {
	.container-holder {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;
		align-items: center;
		padding-top: 15px;
		margin-top: 4rem;

		.resource_image {
			max-width: 120px;

			&:not(:last-of-type) {
				margin-right: 12.5px;
			}

			@media(max-width: 991px) {
				max-width: 30%;
			}
		}

		@media(max-width: 991px) {
			justify-content: space-between;
			margin-top: auto;
		}
	}
}
