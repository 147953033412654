.finance-block {
	border: 1px solid #999999;
	border-radius: 0;
	box-shadow: 6px 6px 12px #00000029;
	padding: 35px 27px 30px;
	margin-top: 20px;

	h2 {
		font-weight: 400;
		margin-bottom: 15px;
		font-size: 1.875rem;
		line-height: 34px;
	}

	p {
		font-family: $font-primary;
		font-size: 1.125rem;
		color: $primary;
		line-height: 20px;
		margin-bottom: 25px;

		a {
			display: inline-block;
			font-size: 1rem;

			&:first-of-type {
				margin-top: 20px;
			}
		}

		a {
			text-decoration: none;
			font-weight: 600;
			font-size: 1.125rem;

			&[href*='mailto:'] {
				color: #008ECF;
			}

			&[href*='tel:'] {
				color: #000000;
			}

			&.btn {
				transition: .3s;

				&:hover {
					color: #FFFFFF;
					background: #1F86C4;
					border-color: #176898;
				}
			}
		}
	}

	.form-control {
		margin-bottom: 10px;
	}

	a.btn.btn-secondary {
		@extend .btn-yellow;
		font-weight: 500;
		margin-top: 25px;
		margin-bottom: 40px;
		font-size: 1.5rem !important;
		@media(max-width: 991px) {
			font-size: 1rem !important;
		}
	}
}
