&.assortiment_overview {
	.content .column.one {
		@include make-col(3);
		@include media-breakpoint-down(sm) {
			flex: none;
			margin: 0;
			padding: 0;
		}
	}

	.content .column.two {
		@include make-col(12);
		@include media-breakpoint-up(md) {
			@include make-col(9);
		}
	}

	.sidebar-filter {
		font-size: 14px;

		.form-group {
			margin-bottom: 1.5rem;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}

		.heading {
			display: none;
			font-size: 20px;
		}

		.close-btn {
			display: none;
			position: absolute;
			top: 20px;
			right: 20px;
			font-size: 24px;
			line-height: 1;
		}

		&.open {
			display: block;
			position: fixed;
			z-index: 999;
			top: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			max-width: 360px;
			height: 100%;
			padding: 20px 20px 75px 20px;
			overflow-x: hidden;
			background-color: $white;

			.heading,
			.close-btn {
				display: block;
			}
		}
	}

	.page_block {
		&.grid {
			justify-content: flex-start;

			.item {
				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}
				@include media-breakpoint-up(xl) {
					@include make-col(4);
				}
			}
		}
	}

	.fixed-filter-button {
		@include media-breakpoint-up(md) {
			display: none;
		}
		position: fixed;
		z-index: 998;
		left: 0;
		right: 0;
		bottom: 30px;
		text-align: center;

		.btn {
			min-width: 230px;
			margin: 0 auto;
			padding: 0.7rem 1rem;
			border-radius: 5px;
			background-color: $primary;
			border-color: $white;
			box-shadow: 0px 3px 6px $box-shadow;
			justify-content: center;

			i {
				margin-right: 10px;
			}
		}
	}
}

&.assortiment_detail {
	.assortiment_detail {
		margin-top: 89px;

		.btn-back {
			display: inline-block;
		}

		.prices {
			display: inline-block;
			margin-bottom: 30px;
			padding: 10px 15px;
			font-size: 20px;
			font-weight: 700;

			.price {
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				.price-value {
					margin-right: 10px;
				}

				&.discount {
					.price-description {
						font-size: 16px;
					}
				}

				&.discounted {
					font-size: 14px;
					text-decoration: line-through;
				}
			}
		}

		h3 {
			&::after {
				content: "";
				display: block;
				max-width: 60px;
				height: 2px;
				margin: 5px 0 15px 0;
			}
		}

		.description {
			margin: 30px 0;
		}

		.characteristics {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					display: flex;
					flex-wrap: wrap;

					.label {
						flex-basis: 50%;
					}
				}
			}
		}

		.downloads {
			margin: 30px 0;

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					display: flex;
					flex-wrap: wrap;

					.label {
						flex-basis: 50%;
					}
				}
			}
		}

		.contact-block {
			margin-top: 30px;
			padding: 30px;

			h2 {
				text-transform: none;
			}
		}
	}
}

.assortiment-section {
	background: $primary;
	border-bottom: 10px solid $base-3;
	padding: 45px 0 20px;
	overflow-x: hidden;

	.container-default {
		@extend .container;

		.container-holder {
			h2 {
				font-size: $h2-font-size;
				color: $white;
				font-family: $font-secondary;
				font-weight: $font-weight-base;
			}

			.mini_filter {
				form {

					.form-group {
						@include media-breakpoint-down(md) {
							display: flex;
						}

						label {
							font-size: $font-size-base_xxs;
							color: $white;
							font-weight: $font-weight-bold;
							@include media-breakpoint-down(md) {
								@include make-col(4);
							}
						}

						.form-control {
							color: $white;
							background: transparent;
							font-weight: $font-weight-light;
							font-size: 1rem;
							height: 40px;
							border-radius: 2px;
							@include media-breakpoint-down(md) {
								@include make-col(8);
							}
						}

						select {
							option {
								background-color: $primary !important;
								color: $white;
								font-weight: $font-weight-light;
								font-size: $font-size-base_sm;
							}
						}

						.submit_button {
							position: relative;
							display: flex;
							justify-content: center;
							align-items: center;
							margin: auto;

							input {
								border: 2px solid #fff;
								border-radius: 2px;
								font-size: 1.5rem !important;
								text-transform: uppercase;
								padding: 0 10px !important;
								text-decoration: none;
								color: #fff;
								font-weight: 400;

								&:hover {
									color: $primary;
									background: #fff;
								}
							}
						}

						&.col-6 {
							@include media-breakpoint-down(md) {
								@include make-col(12);
							}
						}
					}
				}
			}
		}
	}
}
