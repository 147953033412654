.gallery-horizontal {
	display: flex;
	flex-flow: row wrap;
	border-radius: 0;
	justify-content: space-between;
	position: relative;

	.gallery-label {
		position: absolute;
		font-family: "Noto Sans", sans-serif;
		background: url(/images/productlabel.svg);
		color: #fff;
		font-weight: 600;
		padding: 6px 18px;
		padding-bottom: 26px;
		transform: translateY(-50%);
		z-index: 2;
		margin-left: 15px;
	}

	.gallery-item {
		img {
			border-radius: 0;
		}
	}

	.gallery-item,
	.gallery-thumbs {

		a {
			display: Block;

			img {
				max-width: 100%;
			}
		}
	}

	.gallery-thumbs,
	.large-item {
		flex: 0 0 calc(50%);
		max-width: calc(50%);
		z-index: 1;
		@include media-breakpoint-down(sm) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.large-item {
		border-radius: 0;
		@include media-breakpoint-down(sm) {
			margin-bottom: 15px;
		}
	}

	.gallery-thumbs {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-gap: 10px;
		padding-left: 10px;
		position: relative;
		@include media-breakpoint-down(sm) {
			padding-left: 0;
		}

		.gallery-item {

			a {
				display: block;

				.gallery-caption {
					display: none;
				}
			}

			&:nth-of-type(3),
			&:nth-of-type(4) {
				align-items: flex-end;
			}
		}

		.gallery-hidden {
			display: none;
		}

		.gallery-popup-button {
			font-size: 1.5rem;
			position: absolute;
			color: #030C1D;
			right: 15px;
			bottom: 15px;
			background: #fff;
			box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
			font-weight: 500;
			padding: 11px 21px;
			cursor: pointer;
			border-radius: 5px;

			&:hover {
				background: #030C1D;
				color: #fff;
			}

			@media(max-width: 991px) {
				font-size: 1rem;
				right: 0;
				bottom: 0;
			}
		}
	}
}
