.partners-section {
	@media(max-width: 991px) {
		box-shadow: 6px 6px 6px #00000029;
		border: 1px solid #999;
		margin-top: 65px;
	}

	.partners-map {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 70px;
		@media(max-width: 991px) {
			margin-top: -50px;
			max-width: 400px;
		}
		@media(max-width: 467px) {
			max-width: 250px;
		}
		@media(max-width: 310px) {
			max-width: 200px;
		}

		.map {
			content: url('/images/partnericons/partnersmapfull.SVG');
			width: 100%;
			max-width: 100%;
			position: relative;
			z-index: 800;
			pointer-events: none;
		}

		.single_partner_dot {
			position: absolute;
			cursor: pointer;
			transform: translate(-50%, -50%);
			z-index: 801;

			img {
				@media(max-width: 991px) {
					max-width: 16px;
				}
				@media(max-width: 467px) {
					max-width: 12px;
				}
			}

			.partner_dot_content {
				position: relative;

				.partner_name {
					position: absolute;
					font-weight: 900;
					right: 0;
					transform: translateX(calc(100% + 7px));
					white-space: nowrap;
					display: none;
					font-size: 20px;
					color: #000000;
					background: #FBDF02;
					padding: 2px 6px;
					border-radius: 50px;
					@media(max-width: 991px) {
						display: none;
					}
				}
			}

			&:hover {
				z-index: 802;

				.partner_name {
					display: inline;
				}
			}
		}
	}

	.partners-overview {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		@media(max-width: 991px) {
			padding: 0 15px;
		}

		.single_partner {
			flex: 0 0 calc(33.333% - 25px);
			max-width: calc(33.333% - 25px);
			margin-left: 12.5px;
			margin-right: 12.5px;
			margin-bottom: 25px;
			@media(max-width: 991px) {
				flex: 0 0 100%;
				max-width: 100%;
				margin: 0 0 25px;
			}

			&:nth-of-type(3n + 1) {
				margin-left: 0;
			}

			&:nth-of-type(3n + 3) {
				margin-right: 0;
			}

			.partner_logo {
				margin-bottom: 10px;
				max-width: 175px;
				width: 100%;
			}
		}
	}

	.highlighted_partner_wrapper {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 799;
		box-shadow: 6px 6px 6px #00000029;
		border: 1px solid #999999;
		padding: 35px 25px;
		height: 100%;
		display: inline-flex;
		align-items: flex-end;
		width: 100%;
		max-width: 424px;
		max-height: 610px;
		transform: translate(-100px, -55px);
		@media(max-width: 991px) {
			display: none !important;
		}

		.partner_logo {
			max-width: 165px;
		}
	}

	.highlighted_partner_wrapper,
	.single_partner {
		.content_wrapper {
			@media(max-width: 991px) {
				display: flex;
				flex-flow: row wrap;
			}

			h3 {
				color: #092C65;
				font-size: 1.5rem;
			}

			.partner_description {
				font-size: 18px;
				margin-bottom: 0;
				font-weight: 600;
			}

			.partner_address_street,
			.partner_address_city {
				font-size: 18px;
				margin-bottom: 0;
				line-height: 23px;
				font-weight: 500;
			}

			.partner_phone {
				margin-top: 15px;
				margin-bottom: 0;

				a {
					color: #000;
					font-weight: 800;
					font-size: 18px;
				}
			}

			.partner_website,
			.partner_extralink {
				margin-bottom: 0;

				a {
					color: #008ECF;
					font-weight: 500;
				}
			}
		}
	}
}
