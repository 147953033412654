.downloads {
	ul {
		list-style: none;
		display: inline-block;
		padding-left: 0;
		margin-bottom: 0;

		li {
			.file {
				a {
					display: flex;
					border: none;
					font-weight: 600;
					border-radius: 4px;
					padding: 0;
					text-decoration: none;
					transition: 0.3s;
					color: $blue;

					i {
						margin-right: 5px;
					}

					&:hover {
						opacity: 0.75;
						background: none;
						border-color: none;
					}
				}
			}
		}
	}
}
