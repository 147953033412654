.section-icons {
	margin-top: 45px; 
	padding-bottom: 80px;
	@media(max-width: 991px) {
		margin-top: 65px;
	}

	.container {
		.container-holder {
			text-align: center;

			h1 {
				font-size: $h2-font-size;
			}

			h2 {
				color: $primary;
				font-family: $font-primary;
				margin-bottom: $grid-gutter-width / 2;
			}

			.home_block_icons {
				.item {
					@include media-breakpoint-down(sm) {
						@include make-col(6);
						margin-bottom: 0;
					}

					.card {
						border-radius: 6px;
						border: none;
						background: transparent;
						text-decoration: none;
						word-wrap: normal;

						&-image,
						&-body {
							background: transparent;
						}

						&-image {
							font-size: 0;
							padding: 32px 0 10px;
						}

						&-body {
							margin-top: auto;
							flex: 0 0 auto;
							margin-top: 0;
							padding: 0 35px;
							@media(max-width: 991px) {
								padding: 0;
							}

							.card-caption {
								padding: 0;

								.card-title {
									font-size: 1.125rem;
									font-weight: 500;
									text-align: center;
									text-transform: uppercase;
									@media(max-width: 991px) {
										font-size: 0.875rem;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
