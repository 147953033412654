.sidebar-filter {
	padding-top: 80px;

	label {
		font-size: 1.125rem;
		color: #000;
	}

	.form-check {
		padding-left: 25px;

		input {
			display: none;

			+ label {
				line-height: 23px;
				cursor: pointer;

				&:hover {
					opacity: .75;
				}

				&:before {
					content: '';
					background: url('/images/checkbox.svg');
					padding: 8px;
					background-repeat: no-repeat;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
				}
			}

			&:checked {
				+ label {
					&:before {
						background: url('/images/checkbox_checked.svg');
					}
				}
			}
		}
	}

	.form-row {
		input {
			border-color: #030C1D;
		}
	}
}
