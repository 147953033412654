&.assortiment_detail {
	header.header {
		background: $primary;
	}

	h1 {
		color: $blue;
		font-weight: 600;
		text-transform: uppercase;
		@include media-breakpoint-up(lg) {
			font-size: 3.125rem;
			margin-top: 25px;
		}
	}

	h3 {
		color: $blue;
		font-weight: 500;
		padding-top: 25px;
		@include media-breakpoint-up(lg) {
			font-size: 1.875rem;
		}

		&:not(:first-of-type) {
			border-top: 1px solid #008ECF;
		}
	}

	.downloads {
		h3 {
			border-top: 1px solid #008ECF;
		}
	}

	.lead-section {
		.info {
			display: flex;
			justify-content: initial;
			align-content: flex-start;
			margin-top: 0;
			margin-bottom: 30px;
			@include media-breakpoint-up(sm) {
				flex-flow: row wrap;
				max-height: 95px;
				align-items: flex-end;
			}
			@include media-breakpoint-down(sm) {
				flex-flow: row wrap;
				margin-bottom: 50px;
			}

			> * {
				max-width: fit-content;
				margin-bottom: 0;

				@include media-breakpoint-down(sm) {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}

		.back-to-overview-link {
			margin-top: 15px;
		}

		.price-wrapper {
			display: flex;
			flex-flow: row wrap;
			@include media-breakpoint-up(md) {
				margin-left: 30px;
			}

			.price {
				display: flex;
				flex-flow: row wrap;
				margin-left: 30px;

				> span {
					flex: 0 0 100%;
					max-width: 100%;

					&.suffix {
						order: -1;
						line-height: 28px;
					}

					&.amount {
						font-size: 1.5rem;
						color: #176898;
						font-weight: 600;
					}
				}
			}

			.former-price {
				order: -1;

				.amount {
					opacity: 0.6;
					text-decoration: line-through;
				}
			}

			.discount-price {
				flex: 0 0 100%;
				max-width: 100%;

				.amount {
					font-size: 1.5rem;
					color: #176898;
					font-weight: 600;
					margin-right: 5px;
				}
			}
		}
	}

	.gallery-section {
		margin-bottom: 60px;
	}

	.contact-block {

	}

	.content-section {
		margin-bottom: 25px;

		.container-two-columns {
			.container-holder {
				margin-bottom: 0;

				.column {
					&.one {
						@include media-breakpoint-up(lg) {
							flex: 0 0 67%;
							max-width: 67%;
							padding-right: 27.5px !important;
						}
					}

					&.two {
						@include media-breakpoint-up(lg) {
							flex: 0 0 33%;
							max-width: 33%;
							padding-left: 0 !important;
						}
					}

					table {
						border: none;
						margin-top: 40px;

						tbody {
							tr {
								display: flex;
								flex-flow: row wrap;

								td {
									width: auto !important;
									border: none;
									font-size: 1.125rem;
									font-weight: 500;

									&:first-of-type {
										flex: 0 0 125px;
										color: #999999;
									}

									&:last-of-type {
										flex: 0 0 calc(100% - 125px);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.section-icons {
		margin-top: 65px;
	}
}

.assortiment-characteristics {
	border-bottom: 1px solid #008ecf;
	margin-bottom: 30px;
}

.assortiment-extra-content,
.assortiment-characteristics {
	.container {
		display: flex;
		flex-flow: row wrap;
	}
}

.assortiment-extra-content {
	.container-three-columns {
		background: #EDEDF4;
		padding: 45px 0;
		margin-bottom: 65px;

		.column {
			margin-bottom: 0;
		}
	}

	ul {
		margin-bottom: 0;

		li {
			line-height: 28px;

			&::marker {
				color: #176898;
			}
		}
	}
}

.assortiment-characteristics {
	.container-two-columns {
		.column {

			h2 {
				color: #FF8F00;
				margin-bottom: 35px;
			}

			.characteristics {
				ul {
					padding-left: 0;

					li {
						display: flex;
						flex-flow: row wrap;
						justify-content: space-between;

						> div {
							flex: 0 0 calc(50% - 23px);
							max-width: calc(50% - 23px);
							line-height: 36px;

							&.label {
								color: #999999;
							}

							&.characteristic_terms {
								color: #000000;
								font-weight: 500;
							}
						}

						&:not(:last-of-type) {
							> div {
								border-bottom: 1px solid rgba(255, 255, 255, 0.21);
							}
						}
					}
				}
			}
		}
	}
}


.related-products-section.offer {
	padding-bottom: 130px;

	h2 {
		margin-bottom: 70px;
	}

	> .container {
		z-index: 2;
		position: relative
	}

	.assortiment_related_properties {
		.item {
			flex: 0 0 33.333%;
			max-width: 33.333%;
			@include media-breakpoint-down(sm) {
				@include make-col(12);
			}
		}
	}
}

&.assortiment_detail .assortiment_related_properties .item {
	flex: 0 0 33.333%;
	max-width: 33.333%;

	.property-terms-list {
		color: #fff;
	}
}
