.eyecatcher {
	&.large {
		@media (max-width: 991px) {
			min-height: calc(100vh - 303px);
		}

		.eyecatcher-container {
			.container-holder {
				.owl-carousel {
					min-height: 1px;
					height: calc(100vh - 120px);
					@media (max-width: 991px) {
						height: calc(100vh - 303px);
					}

					&:after {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						background: transparent;
					}

					.owl-stage-outer {
						.owl-stage {
							.owl-item {
								.item {
									position: relative;
									min-height: calc(100vh - 120px);
									@media (max-width: 991px) {
										min-height: calc(100vh - 303px);
									}

									&::after {
										content: "";
										position: absolute;
										background: linear-gradient(
											180deg,
											rgba($body-color, 0),
											$body-color
										);
										bottom: 0;
										left: 0;
										height: 50%;
										width: 100%;
										opacity: 0.5;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.banner_content {
		@include media-breakpoint-up(lg) {
			position: absolute;
			left: 50%;
			right: auto;
			bottom: 0;
			transform: translate(-50%, -50%);
			z-index: 1;
		}

		> .container-holder {
			text-align: center;
		}

		h2 {
			font-weight: $font-weight-base;
			@include media-breakpoint-up(md) {
				font-size: 3.125rem;
			}
			@media (max-width: 991px) {
				margin-bottom: 0;
				font-size: 1.875rem;
			}
		}

		h2,
		p {
			color: $white;
		}

		p {
			font-size: 1.125rem;
			font-family: $font-primary;
			@include media-breakpoint-up(lg) {
				margin-bottom: 25px;
			}
			@include media-breakpoint-up(sm) {
				font-size: $h1-font-size;
			}
			@include media-breakpoint-down(md) {
				font-weight: 100;
			}
		}

		.btn {
			@include media-breakpoint-up(lg) {
				&:first-of-type {
					margin-right: 15px;
				}
			}
			@include media-breakpoint-down(sm) {
				padding: 5px 10px;
				margin-bottom: 15px;
			}
		}
	}

	.eyecatcher-content-wrapper {
		@media (max-width: 991px) {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 2;
			padding: 0 35px;
		}
	}
}
