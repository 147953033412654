&.assortiment_overview {
	header.header {
		background: $primary;
	}

	.property-terms-list {
		list-style: none;
		padding-left: 0;
		min-height: 96px;

		.property-terms-list-item-label {
			width: 50%;
			display: block;
			float: left;
			color: #fff !important;
		}

		.property-terms-list-item-value {
			width: 50%;
			display: block;
			float: left;
			color: #fff !important;


			.property-terms-list-item-value-list {
				list-style: none;
				padding-left: 0;

				.property-terms-list-item-value-list-item {
					color: #fff !important;
				}
			}
		}
	}

	.content {
		padding: 0;

		h1:not(.resultcount) {
			color: $blue;
			font-size: 3.125rem;
			font-weight: 600;
			margin-top: 25px;
		}

		.resultcount {
			font-size: 1.875rem;
			font-weight: 500;
			display: none; 
		}

		.page_block.assortiment_overview {
			margin-bottom: 25px;
			flex: 0 0 100%;
			max-width: 100%;
		}

		.js-assortiment-block {
			.mb-4 {
				margin-bottom: 40px !important;
			}
		}
	}
}
