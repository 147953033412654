footer {
	&.footer {
		.footer-top {
			background: $primary;

			strong {
				text-transform: uppercase;
				font-weight: 500;
				color: #fff;
			}

			a:not(.btn-transparent) {
				color: #fff;
				display: block;
			}

			p {
				color: #979BA2;
			}

			.vocabulary_term_list {
				margin-top: 25px;

				li {
					a {
						margin-right: 10px;

						span {
							display: none;
						}

						i {
							font-size: 1.75rem;
							opacity: .6;
						}

						&:hover {
							i {
								opacity: 1;
							}
						}
					}
				}
			}
		}

		.footer-socket {
			background: $primary;
			padding: 0;

			a {
				color: #fff;
			}
		}
	}
}
