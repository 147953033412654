.map-section {
	padding-top: 75px;
	padding-bottom: 215px;
	@media(max-width: 991px) {
		padding-bottom: 415px;
	}

	.column.one {
		@include media-breakpoint-up(lg) {
			padding-right: 0;
			flex: 0 0 70%;
			max-width: 70%;
		}

		h2 {
			&:first-of-type {
				font-family: $font-secondary;
				transform: rotate(-8deg);
				color: $red;
				font-size: 2.5rem;
				margin-bottom: 60px;
				padding-left: 0;
				@media(max-width: 991px) {
					font-size: 1.563rem
				}
			}
		}

		h2,
		p {
			margin-bottom: 0;
			@include media-breakpoint-up(lg) {
				padding-left: 65px;
			}
		}

		p {
			max-width: 455px;
		}

		.btn-blue {
			margin-top: 25px;
		}

		.link {
			@include media-breakpoint-up(lg) {
				margin-left: 65px;
				margin-top: 25px;
			}
			@extend .btn-blue;
			@extend .btn;
		}
	}
}
