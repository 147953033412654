& {
	h2 {
		font-weight: 500;
		margin-bottom: 20px;
	}

	a {
		color: $red;
	}

	p {
		font-size: 1.125rem;
		font-weight: 500;
	}

	.dark-background {

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			color: #fff;
		}
	}

	section.spacer {
		@include media-breakpoint-up(lg) {
			padding: 95px 0;
		}

		@include media-breakpoint-down(lg) {
			padding: 35px 0;
		}
	}

	.container.full-width {
		max-width: 100% !important;

		&.container-two-columns {
			.column {
				margin-bottom: 0;

				@media(max-width: 991px) {
					flex: 0 0 100%;
					max-width: 100%;
				}

				&.one {
					padding-right: 0 !important;
				}

				&.two {
					padding-left: 0 !important;

					@media(max-width: 991px) {
						padding: 45px !important;
					}
				}
			}
		}
	}

	.container-two-columns .container-holder {
		max-width: 100vw;
	}

	.dark-background {
		background-color: $primary;
	}

	.empty-spacer-row {
		@include media-breakpoint-up(lg) {
			height: 120px;
		}
	}

	.grey-background {
		background-color: #f2f2f2;
	}

	.dark-grey-background {
		background-color: #DEDEDE;
	}

	.map-background {
		background-image: url('/images/mapbackground.png');
		background-repeat: no-repeat;
		background-position: 100% calc(100% - 30px);

		@media(max-width: 991px) {
			background-repeat: no-repeat;
			background-position: 66% calc(100% - 30px);
			background-size: 180%;
		}
	}
}

&:not(.home) {
	padding-top: 140px;

	header {
		background: $primary;
	}
}

// whatsapp-icon
.whatsapp-icon {
	position: fixed;
	top: 160px;
	right: 20px;
	margin: 0;

	li {
		margin: 0;

		a {
			display: block;
			color: #25d366;
			transition: 0.5s;

			i {
				font-size: 48px;
			}

			span {
				display: none;
			}

			&:hover {
				color: $black;
			}
		}
	}

	@media(max-width: 1279px) {
		display: none !important;
	}
}
