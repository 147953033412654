.js-assortiment-block {
	.grid.page_block.assortiment_overview {
		.item {
			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}
		}
	}
}

.js-assortiment-block,
.owl-stage,
& {
	.grid.page_block.assortiment_overview,
	.owl-item,
	.page_block {
		.item {
			.card {
				overflow: visible;

				&-body {
					padding: 15px 15px 45px;
					border-radius: 0 2px 2px 0;
					background: $primary;

					.card-caption {
						display: flex;
						flex-direction: column;
						padding: 0 20px;

						.card-image-caption {
							min-height: 84px;

							.card-image-label {
								font-family: $font-secondary;
								color: $yellow;
								font-size: 2.25rem;
								transform: rotate(-5deg) translateX(-20px);
								padding-bottom: 30px;
							}
						}

						.card-title-link {
							.card-title {
								font-size: 1.875rem;
								color: #fff;
								font-weight: 500;
							}
						}

						.card-price-value,
						.card-subtitle,
						.card-price-message {
							font-size: 1rem;
							color: #fff;
							margin-bottom: $grid-gutter-width;
						}

						.card-description {
							min-height: 50px;
							max-height: 50px;
							margin-bottom: $grid-gutter-width;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;

							&-content {
								font-size: $font-size-base_sm;
							}

							&::after {
								content: none;
							}
						}

						.card-description-content {
							color: #fff;
							font-size: 1.125rem;
							font-weight: 400;
						}
					}

					.card-buttons {
						position: relative;
						z-index: 1;
						text-align: center;

						.card-btn {
							text-transform: none;
							@extend .btn-transparent;
						}
					}

					.card-prices {
						min-height: 73.5px;

						.card-price {
							&.sale {
								> .card-price-value {
									text-decoration: line-through;
									margin-bottom: 0;
									font-size: 17px;
								}
							}

							.card-price.discount {
								display: flex;

								.card-price-description {
									font-size: 17px;
									color: #fff;
									font-weight: 400;
									order: 1;
									margin-left: 5px;
								}
							}

							&.per-month {
								color: #fff;
								font-size: 17px;

								a {
									color: #FFDF00;
								}
							}
						}
					}

					.card-price-message {
						color: #fff;
						font-size: 1.125rem;
						font-weight: 600;
					}
				}

				&-image {
					.card-image-link {
						.card-img {
							border-radius: 5px 5px 0 0;
						}
					}
				}
			}
		}
	}
}

.main {
	.content {
		.blog-overview {
			.page_block {
				&.blog_filter {
					display: none;
				}
			}

			.page_block {
				&.grid {
					.item {

						@include make-col(12);

						.card {
							border: none;

							.card-image {
								background: transparent;

								.card-img {
									border-radius: 2px;
								}
							}

							.card-body {
								padding: 16px 39px;

								.card-title {
									font-size: 1.5rem !important;
									font-weight: 400;
								}

								.card-subtitle {
									order: -1;
									font-family: 'Dancing Script';
									font-weight: 100;
									font-size: 1rem;
									margin-bottom: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.card-price-description-leverbaar-vanaf {
	font-family: "Dancing Script", sans-serif;
	font-size: 1.375rem;
}


&.blog_post_overview {
	.item {
		.card-body {
			.card-caption {
				padding-top: 10px;
			}

			.card-buttons {
				text-align: right;
			}
		}
	}
}
