@import './0_config/vendor-variables';
@import '~framework/assets/scss/Dev/0_config/vendor-variables';
@import '~framework/assets/scss/Dev/setup';
@import './0_config/website-variables';
@import '~framework/assets/scss/Dev/0_config/website-variables';

@import '~framework/assets/scss/Dev/1_vendor/bootstrap';

body.default {
	@import '~framework/assets/scss/Dev/base';

	@import './3_component/button';
	@import './3_component/card';
	@import './3_component/downloads';
	@import './3_component/gallery-horizontal';
	@import './3_component/section-icons';
	@import './3_component/homepage-categories';
	@import './3_component/usp-section';
	@import './3_component/map-section';
	@import './3_component/sidebar-filter';
	@import './3_component/contactblock';
	@import './3_component/financeblock';
	@import './3_component/partnersmap';
	@import './3_component/popup';


	@import './6_theme/module.assortiment';
	@import './3_component/tommie';


	@import './2_layout/global';
	@import './2_layout/header';
	@import './2_layout/eyecatcher';


	@import './2_layout/home';
	@import './2_layout/assortiment_overview';

	@import './2_layout/footer';
	//@import './2_layout/section';
	//@import './2_layout/base';
	@import './2_layout/assortiment_detail';


}
