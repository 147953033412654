.homepage-categories {

    margin-top: 10px; 

	.card-columns {
		grid-column-gap: 0;
		@media(max-width: 991px) {
			display: flex;
			flex-flow: row wrap;
		}

		.item {
			@media(max-width: 991px) {
				flex: 0 0 100%;
				max-width: 100%;
			}

			> a {
				position: relative;
				border: none;
				@media(max-width: 991px) {
					margin-bottom: 0;
				}

				.card-body {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 10px;
					background: transparent;
					text-align: center;;
					padding: 0;

					.card-title {
						border: 2px solid #fff;
						color: #fff;
						border-radius: 5px;
						font-size: 1.5rem !important;
						line-height: 1.625rem;
						text-align: left;
						padding: 8px 20px;
						font-weight: 500;
						z-index: 1;
						margin-left: auto;
						margin-right: auto;
					}
				}

				&:hover {
					text-decoration: none;

					&:hover {
						.card-body {
							.card-title {
								color: $primary;
								background: #fff;
							}
						}
					}
				}
			}
		}
	}
}
